import * as React from "react";
import styled from "styled-components";
import Theme from "../../../vars/ThemeOptions";

const Styled: { [key: string]: string } = {};
Styled.Wrap = styled.div<{
  uppercase?: boolean;
  bold?: boolean;
  titleSize?: string;
  titleColor?: string;
  mobTitleColor?: string;
}>`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  h2 {
    ${({ uppercase }) =>
      uppercase === false ? "" : "text-transform: uppercase;"}
    color: ${({ titleColor }) =>
      titleColor ? titleColor : Theme.hex("primary")};
    font-size: ${({ titleSize }) => (titleSize ? titleSize : "default")};
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
    margin-bottom: 0;
  }
  .p,
  p {
    color: ${({ titleColor }) =>
      titleColor ? titleColor : Theme.hex("primary")};
    font-size: ${({ titleSize }) => (titleSize ? titleSize : "default")};
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
    margin-bottom: 0;
  }
  .green-bar {
    height: 100%;
    width: 25vw;
    background: ${Theme.hex("primary")};
    position: absolute;
    top: 0px;
    left: -27vw;
  }
  @media only screen and (max-width: 991px) {
    h2 {
      color: ${({ mobTitleColor, titleColor }) =>
        mobTitleColor
          ? mobTitleColor
          : titleColor
          ? titleColor
          : Theme.hex("primary")} !important;
    }
  }
`;

type SectionTitleProps = {
  title: string;
  titleColor?: string;
  mobTitleColor?: string;
  titleSize?: string;
  bold?: boolean;
  uppercase?: boolean;
  copy?: string;
};

const SectionTitle = ({
  title,
  titleColor,
  mobTitleColor,
  titleSize,
  bold,
  uppercase,
  copy
}: SectionTitleProps) => {
  const TitleComp = title;
  return (
    <Styled.Wrap
      titleColor={titleColor}
      mobTitleColor={mobTitleColor}
      titleSize={titleSize}
      bold={bold}
      uppercase={uppercase}>
      {typeof title === "string" ? (
        <h2>
          {title === "" ? (
            <span style={{ visibility: `hidden` }}>.</span>
          ) : (
            title
          )}
        </h2>
      ) : typeof title === "function" ? (
        <TitleComp />
      ) : null}
      {copy && <div className="p">{copy}</div>}
      <div className="green-bar" />
    </Styled.Wrap>
  );
};

export default SectionTitle;
