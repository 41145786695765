import * as React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../../common/Section/SectionComp";
import Theme from "../../../vars/ThemeOptions";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { Img, WmkImage } from "wmk-image";
import { RichText } from "../../../classes/RichText";

export const Styled: { [key: string]: Function } = {};
Styled.Wrap = styled.div`
  position: relative;
  top: -7rem;

  margin-bottom: 0;
  @media only screen and (max-width: 991px) {
    padding-top: 18rem;
  }
  .bgImage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
Styled.ContentCol = styled(Col)`
  color: white;
  b {
    color: ${Theme.hex("primary")} !important;
  }
  ul {
    margin-bottom: 2rem;
    li {
      p {
        margin-top: -25px;
      }
    }
    li::before {
      content: "\\2022";
      color: ${Theme.hex("primary")} !important;
      font-weight: bold;
      font-size: 1.5rem;
      display: inline-block;
      width: 1em;
      margin-left: -20px;
      margin-top: -20px;
      margin-bottom: -3px;
    }
  }
  h6 {
    margin-top: 2rem;
    b {
      font-size: 1.5rem;
      color: ${Theme.hex("white")} !important;
    }
  }
  blockquote {
    margin: 1rem 0 1.5rem;
    a {
      display: block;
      background: ${Theme.hex("primary")};
      width: 16.5rem;
      padding: 0.5rem 0;
      color: ${Theme.hex("white")};
      font-weight: bold;
      text-align: center;
      transform: scale(1);
      transition: all 0.3s ease;
      text-decoration: none;
      &:hover {
        transform: scale(1.1);
        transition: all 0.3s ease;
      }
    }
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 991px) {
    //color: ${Theme.hex("darkGray")};
  }
  @media only screen and (max-width: 525px) {
    margin-top: 1.75rem;
  }
`;
Styled.FormButton = styled.button`
  border: none;
  margin-bottom: 1.5rem;
  background: ${Theme.hex("primary")};
  width: 17rem;
  padding: 0.5rem 3rem;
  color: ${Theme.hex("white")};
  font-weight: bold;
  transform: scale(1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease;
  }
`;

const StyledFade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to right,
    rgba(19, 24, 47, 1) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  @media only screen and (max-width: 991px) {
    left: 0;
    height: 100%;
    background: linear-gradient(
      45deg,
      rgba(19, 24, 47, 1) 0%,
      rgba(19, 24, 47, 0.65) 100%
    );
  }
`;
Styled.BgImageWrap = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: -30vw;
  width: 130vw;
  height: 150%;
  @media only screen and (max-width: 991px) {
    left: 0;
    width: 100vw;
    height: 30rem;
    background-position: top right;
  }
  @media only screen and (max-width: 525px) {
    height: 32.5rem;
  }
`;

const ImageWrap = styled.div``;

type MagLpProps = {
  image: Img;
  content: RichText;
  title: string;
};

const MagazineLP = ({ image, content, title }: MagLpProps) => {
  return (
    <Styled.Wrap>
      <Section
        secTitle={title}
        secImage={image}
        noBgImage
        bgFade
        padding="7rem 0 0 0"
        mobTitleColor={Theme.hex("white")}>
        <Container fluid>
          <Row>
            <Styled.ContentCol lg={8} md={12}>
              <RichTextReact content={content} />
            </Styled.ContentCol>
          </Row>
        </Container>
      </Section>
      <WmkImage image={image} className="bgImage" />
      <StyledFade />
    </Styled.Wrap>
  );
};

export default MagazineLP;
