import { graphql } from "gatsby";
import * as React from "react";
import { Img } from "wmk-image";
import { RichText } from "../classes/RichText";
import Layout from "../components/layout/MainLayout";
import MagazineLP from "../components/routes/MagazineLP/MagazineLPComp";
import Theme from "../vars/ThemeOptions";
import { LpOverlayStyleFields } from "./fragments/NodeLandingPageFields";

type LpOverlayStyleQuery = {
  data: {
    page: LpOverlayStyleFields;
  };
};

const LpOverlayStyle = ({ data }: LpOverlayStyleQuery) => {
  return (
    <Layout
      layoutProps={{
        logoSlug: "5280",
        headLogoColor: Theme.hex("white"),
        showCta: false,
        footLogoColor: Theme.hex("white"),
        footBorderColor: Theme.hex("primary"),
        footBorderThickness: "5px",
        footMobileBg: "#13182F"
      }}>
      <MagazineLP
        title={data.page.headline}
        content={new RichText(data.page.content)}
        image={new Img(data.page.bgImage)}
      />
    </Layout>
  );
};

export default LpOverlayStyle;

export const query = graphql`
  query lpOverlayStyleQuery($slug: String!) {
    page: contentfulLpOverlayStyle(slug: { eq: $slug }) {
      ...NodeLpOverlayStyleFields
    }
  }
`;
